/* :root {
	--green: #4ecdc4;
	--darkGrey: #292f36;
	--white: #fffffa;
	--black: #000103;
	--orange: #fa8334;
	--red: #ff312e;
} */

.container {
	box-sizing: border-box;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
		"Droid Sans", "Helvetica Neue", sans-serif;
	text-shadow: 0 2px 0 rgba(0, 0, 0, 0.07);
	color: var(--black);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.cspage {
	background-image: url(../images/floating-cogs.svg);
	background-color: var(--darkGrey);
	min-height: calc(100vh - 100px);
	background-attachment: fixed;
}