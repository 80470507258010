#insurance {
    background-color: #fff;
}

#insurance .navbar.scrolling-navbar {
    background-color: #6f7782;
}

#insurance #post {
    padding-top: 6rem;
}

#insurance #post a,
#insurance #recent a,
#insurance #comments a {
    color: inherit;
    text-decoration: none;
}

#insurance .rgba-gradient {
    background: -webkit-linear-gradient(45deg, rgba(83, 125, 210, 0.4), rgba(178, 30, 123, 0.4) 100%);
}
#insurance .avatar {
    max-width: 100px;
}

#insurance footer.page-footer {
    background-color: #6f7782;
}

#insurance .footer-copyright {
    margin-top: -1.5rem;
}
