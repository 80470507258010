.social-links {
	grid-area: social;
	align-self: end;
	display: grid;
	grid-auto-flow: column;
	grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
	justify-items: center;
	grid-gap: 30px;
}

.social-link {
	color: var(--white);
	grid-column: minmax(50px, 1fr);
	font-weight: 500;
	display: grid;
	grid-gap: 10px;
	justify-items: center;
	text-decoration: none;
	transition: all 0.3s ease-in;
}

.social-link:hover {
	filter: brightness(85%);
	text-decoration: underline;
}

.social-logo {
	width: 60px;
}

.social-text {
	font-size: 16px;
}

@media (max-width: 700px) {
	.social-links {
		margin-top: 20px;
		grid-gap: 10px;
	}
}
