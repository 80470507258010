.subscribe {
	grid-area: subscribe;
	display: grid;
	grid-gap: 10px;
	grid-template-columns: [input] 4fr [button] 1fr;
}

.subscribe-email {
	grid-area: input;
	border-radius: 4px;
	height: 40px;
	background-color: var(--white);
	border: 1px solid var(--black);
	color: var(--black);
	padding-left: 10px;
	outline: none;
	font-size: 16px;
	transition: all 0.2s ease-in;
}

.subscribe-email::placeholder {
	color: var(--blue);
}

.subscribe-email:focus,
:hover {
	border-color: var(--blue);
}

.subscribe-button {
	grid-area: button;
	height: 40px;
	background-color: var(--blue);
	color: var(--white);
	border: 1px solid var(--black);
	border-radius: 4px;
	padding: 10px;
	font-size: 16px;
	font-weight: bolder;
	cursor: pointer;
	transition: all 0.2s ease-in;
}

.subscribe-button:hover,
.subscribe-button:focus {
	outline: none;
	filter: brightness(85%);
}

@media (max-width: 700px) {
	.subscribe {
		grid-template-areas: "input""button";
	}

	.subscribe-button,
	.subscribe-email {
		width: 250px;
		grid-column: 1 / -1;
	}
}