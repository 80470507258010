#posts3 {
	background-color: #fff;
}

#posts3 #home .view {
	background: url("https://www.twincities.com/wp-content/uploads/2017/08/jea-1492-capitol-aerial.jpg") no-repeat
		center center;
	background-size: cover;
	height: 60vh !important;
}

#posts3 h1 {
	letter-spacing: 8px;
}

#posts3 h5 {
	letter-spacing: 3px;
}

#posts3 .hr-light {
	border-top: 3px solid #fff;
	width: 80px;
}

#posts3 footer.page-footer {
	background-color: #6f7782;
}

#posts3 .btn-floating i {
	font-size: 1.25rem;
	line-height: 47px;
	display: inline-block;
	width: inherit;
	text-align: center;
	color: #fff;
}

#posts3 .top-nav-collapse {
	/* background-color: #6f7782 !important; */
}

#posts3 {
	background-color: #eee;
}
