.title {
	grid-area: title;
	display: grid;
	grid-gap: 20px;
	width: 100%;
	max-width: 700px;
	grid-template-columns: 1fr auto 1fr;
	align-items: center;
}

/*
Thanks to Wes Bos for the gradient trick
https://cssgrid.io
*/
.title:before,
.title:after {
	display: block;
	content: "";
	height: 10px;
}

.title:before {
	background: linear-gradient(to left, var(--blue), transparent);
}

.title:after {
	background: linear-gradient(to right, var(--blue), transparent);
}
