#contact .view {
	background: url('background-image: url("https://mdbootstrap.com/img/Photos/Horizontal/Nature/full page/img(11).jpg");')
		no-repeat center center;
	background-size: cover;
	background-color: #fff;

	height: 100vh;
}

#contact .card {
	background-color: #fff;

	/* background-color: rgba(255, 255, 255, 0.85); */
}

#contact h6 {
	line-height: 1.7;
}

#contact .form-check {
	margin-bottom: 0 !important;
}

#contact .rgba-gradient {
	background: -webkit-linear-gradient(98deg, rgba(22, 91, 231, 0.5), rgba(255, 32, 32, 0.5) 100%);
}
