#posts2 {
	background-color: #fff;
}

#posts2 #home2 .view {
	background: url("https://miro.medium.com/max/10000/1*9DSoK1LOPrNSnpgV_2R-rQ.jpeg") no-repeat center center;
	/* background-image: url(/client/src/assets/images/Team.jpg); */
	background-size: cover;
	height: 60vh;
}

#posts2 h1 {
	letter-spacing: 8px;
}

#posts2 h5 {
	letter-spacing: 3px;
}

#posts2 .hr-light {
	border-top: 3px solid #fff;
	width: 80px;
}

#posts2 footer.page-footer {
	background-color: #6f7782;
}

#posts2 .btn-floating i {
	font-size: 1.25rem;
	line-height: 47px;
	display: inline-block;
	width: inherit;
	text-align: center;
	color: #fff;
}

#posts2 .top-nav-collapse {
	/* background-color: #6f7782 !important; */
}

#posts2 {
	background-color: #eee;
}
