.profile-card {
    margin-top: 100px;
}

.profile-card .avatar {
    max-width: 150px;
    max-height: 150px;
    margin: -70px auto 0;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    overflow: hidden;
}

ul.striped li {
    padding: .5rem;
    border-bottom: 1px solid rgba(0, 0, 0, .15);
}