.upload-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 80vh;
}

.upload-tbl td,
.upload-tbl th {
    vertical-align: top;
    padding: 5px;
    margin: 50;
}

.upload-tbl th {
    text-align: left;
}

.dropzone {
    /* overflow-y: scroll; */
    overflow-x: hidden;
    position: relative;
    width: 600px;
    height: 500px;
    border-width: 10px;
    border-color: grey;
    border-style: dotted;
    border-radius: 30px;
    padding-left: 100;
}

.upload-tbl input[type="submit"] {
    margin-left: 0 !important;
}

.upload-tbl input[type="text"] {
    width: 99%;
    height: 20px;
}

.customDatePickerWidth,
.customDatePickerWidth > div.react-datepicker-wrapper,
.customDatePickerWidth
    > div
    > div.react-datepicker__input-container
    .customDatePickerWidth
    > div
    > div.react-datepicker__input-container
    input {
    width: 100%;
    height: 100%;
}

.react-datepicker__input-container {
    width: inherit;
    height: inherit;
    /* border-radius: 15px; */
}

.react-datepicker__input-container input {
    width: inherit;
    height: 100%;
    border-radius: 5px;
    height: 40px;
    border-width: 0.5px;
    padding: 10px;
}

.react-datepicker-wrapper {
    width: 100%;
    /* border-radius: 15px; */
    /* height: ; */
}
